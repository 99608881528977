<template>
  <div class="container-xl">
    <v-row>
      <app-list v-model="darkMode" @handle="handle" :items="items" />
      <v-col cols="9">
        <template v-if="index === 0">
          <app-profile :user="user" />
        </template>
        <template v-else-if="index === 1">
          <app-change-password />
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppList from "@/components/settings/list";
import AppProfile from "@/components/settings/profile";
import AppChangePassword from "@/components/settings/change-password";
import AppContract from "@/components/settings/contract";
import { mapGetters, mapActions } from "vuex";
import Cookies from "js-cookie";
import { confirm } from "@/controllers/alert";
export default {
  components: {
    AppList,
    AppProfile,
    AppChangePassword,
    AppContract,
  },
  data: () => ({
    file: null,
    index: 0,
    darkMode: null,
    items: [
      { text: "general", icon: "user-circle", value: 0, color: "#4A90E2" },
      { text: "change_password", icon: "key", value: 1, color: "#50C878" },
      { text: "dark_mode", icon: "moon", value: 2, color: "#FFA500" },
      { text: "logout", icon: "sign-out-alt", value: 3, color: "#FFD700" },
    ],
  }),
  created() {
    const thems = localStorage.getItem("darkMode");
    if (thems) {
      if (thems.length === 4) {
        this.darkMode = true;
        this.$vuetify.theme.dark = true;
      } else {
        this.darkMode = false;
        this.$vuetify.theme.dark = false;
      }
    }
  },
  watch: {
    darkMode(value) {
      this.$vuetify.theme.dark = value;
      localStorage.setItem("darkMode", value);
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      fetch: "index/fetch",
    }),

    async changePackage(user) {
      await this.fetchPackage();
      await success(this, "process_success");
      this.$store.commit("auth/SET_USER", user);
      Cookies.set("user", user);
    },
    handle(index) {
      if (index !== 2 && index !== 3) {
        this.index = index;
      } else if (index === 2) {
        this.darkMode = !this.darkMode;
      } else if (index === 3) {
        this.logoutMethod();
      }
    },
    async logoutMethod() {
      try {
        const { isConfirmed } = await confirm({
          props: this,
          text: "want_to_logout",
        });

        if (isConfirmed) {
          await this.logout(this);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
