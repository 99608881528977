import {
  required,
  minLength,
  numeric,
  email,
  sameAs,
} from "vuelidate/lib/validators";

// CREATE PACKAGE
const PACKAGE = {
  validations: {
    form: {
      name: {
        required,
      },
      day: {
        required,
        numeric,
      },
      icon: {
        required,
      },
      month_count: {
        required,
        numeric,
      },
      product_count: {
        required,
        numeric,
      },
      bill_count: {
        required,
        numeric,
      },
      user_count: {
        required,
        numeric,
      },
      branch_count: {
        required,
        numeric,
      },
      price: {
        required,
        numeric,
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Name is required.");
      return errors;
    },
    dayErrors() {
      const errors = [];
      if (!this.$v.form.day.$dirty) return errors;
      !this.$v.form.day.required && errors.push("Day is required.");
      !this.$v.form.day.numeric && errors.push("Day must be numeric.");
      return errors;
    },
    iconErrors() {
      const errors = [];
      if (!this.$v.form.icon.$dirty) return errors;
      !this.$v.form.icon.required && errors.push("Icon is required.");
      return errors;
    },
    month_countErrors() {
      const errors = [];
      if (!this.$v.form.month_count.$dirty) return errors;
      !this.$v.form.month_count.required &&
        errors.push("Month count is required.");
      !this.$v.form.month_count.numeric &&
        errors.push("Month count must be numeric.");
      return errors;
    },
    product_countErrors() {
      const errors = [];
      if (!this.$v.form.product_count.$dirty) return errors;
      !this.$v.form.product_count.required &&
        errors.push("Product count is required.");
      !this.$v.form.product_count.numeric &&
        errors.push("Product count must be numeric.");
      return errors;
    },
    bill_countErrors() {
      const errors = [];
      if (!this.$v.form.bill_count.$dirty) return errors;
      !this.$v.form.bill_count.required &&
        errors.push("Bill count is required.");
      !this.$v.form.bill_count.numeric &&
        errors.push("Bill count must be numeric.");
      return errors;
    },
    user_countErrors() {
      const errors = [];
      if (!this.$v.form.user_count.$dirty) return errors;
      !this.$v.form.user_count.required &&
        errors.push("User count is required.");
      !this.$v.form.user_count.numeric &&
        errors.push("User count must be numeric.");
      return errors;
    },
    branch_countErrors() {
      const errors = [];
      if (!this.$v.form.branch_count.$dirty) return errors;
      !this.$v.form.branch_count.required &&
        errors.push("Branch count is required.");
      !this.$v.form.branch_count.numeric &&
        errors.push("Branch count must be numeric.");
      return errors;
    },
    priceErrors() {
      const errors = [];
      if (!this.$v.form.price.$dirty) return errors;
      !this.$v.form.price.required && errors.push("Price is required.");
      !this.$v.form.price.numeric && errors.push("Price must be numeric.");
      return errors;
    },
  },
};

// CREATE STORE
const CREATE_STORE = {
  validations: {
    form: {
      name: {
        required,
      },
      mobile_no: {
        required,
        numeric,
      },
      address: {
        required,
      },
      userId: {
        required,
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Name is required.");
      return errors;
    },
    mobile_noErrors() {
      const errors = [];
      if (!this.$v.form.mobile_no.$dirty) return errors;
      !this.$v.form.mobile_no.required && errors.push("Mobile no is required.");
      !this.$v.form.mobile_no.numeric &&
        errors.push("Mobile no must be numeric.");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.form.address.$dirty) return errors;
      !this.$v.form.address.required && errors.push("Address is required.");
      return errors;
    },
    userIdErrors() {
      const errors = [];
      if (!this.$v.form.userId.$dirty) return errors;
      !this.$v.form.userId.required && errors.push("User id is required.");
      return errors;
    },
  },
};

// CREATE USER
const CREATE_USER = {
  validations: {
    form: {
      first_name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone_no: {
        required,
        numeric,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  computed: {
    first_nameErrors() {
      const errors = [];
      if (!this.$v.form.first_name.$dirty) return errors;
      !this.$v.form.first_name.required &&
        errors.push("First name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push("Email is required.");
      !this.$v.form.email.email && errors.push("Email must be valid.");
      return errors;
    },
    phone_noErrors() {
      const errors = [];
      if (!this.$v.form.phone_no.$dirty) return errors;
      !this.$v.form.phone_no.required && errors.push("Phone no is required.");
      !this.$v.form.phone_no.numeric &&
        errors.push("Phone no must be numeric.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Password is required.");
      !this.$v.form.password.minLength &&
        errors.push("Password must be at least 6 characters.");
      return errors;
    },
    confirm_passwordErrors() {
      const errors = [];
      if (!this.$v.form.confirm_password.$dirty) return errors;
      !this.$v.form.confirm_password.required &&
        errors.push("Confirm password is required.");
      !this.$v.form.confirm_password.sameAsPassword &&
        errors.push("Confirm password must be same as password.");
      return errors;
    },
  },
};

// CREATE BRANCH
const CREATE_BRANCH = {
  validations: {
    form: {
      name: {
        required,
      },
      mobile_no: {
        required,
        numeric,
      },
      address: {
        required,
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Name is required.");
      return errors;
    },
    mobile_noErrors() {
      const errors = [];
      if (!this.$v.form.mobile_no.$dirty) return errors;
      !this.$v.form.mobile_no.required && errors.push("Mobile no is required.");
      !this.$v.form.mobile_no.numeric &&
        errors.push("Mobile no must be numeric.");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.form.address.$dirty) return errors;
      !this.$v.form.address.required && errors.push("Address is required.");
      return errors;
    },
  },
};

export { PACKAGE, CREATE_STORE, CREATE_USER, CREATE_BRANCH };
